const categories = [
    { name: "Keyboard", path: "keyboards" },
    { name: "Mouse", path: "Gaming-mouse" },
    { name: "monitor", path: "monitors" },
    { name: "Computer Components", path: "Discount-pc" },
    { name: "Motherboard", path: "Motherboards" },
    { name: "Microphone", path: "Microphone" },
    { name: "Capture cards", path: "capture-cards" },
    { name: "special", path: "index.php?route=product/special" },
    { name: "Mousepad", path: "mousepad" }
];

export default categories;