import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import theme from './theme';
import App from './App';




ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider resetCSS={true} theme={theme}>
      <ColorModeScript initialColorMode={'black'} />
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
