import { AspectRatio, Box, Button, Center, Divider, GridItem, HStack, IconButton, Image, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, SimpleGrid, Spinner, useDisclosure, VStack } from '@chakra-ui/react';
//import { ImVideoCamera } from 'react-icons/im';
import React, { useState } from 'react';
//import ColorModeToggle from './ColorModeToggle';
import { Link } from 'react-router-dom';
import InputAsButton from './InputAsButton';
import { IoMenu, IoSearchSharp } from 'react-icons/io5';
//import MovieCard from '../MovieCard';
//import { GoPerson } from 'react-icons/go';
import { AiTwotoneStar, AiFillCodeSandboxSquare, AiFillProfile } from "react-icons/ai";
import { BsCartCheckFill } from "react-icons/bs";

import axios from 'axios';
const Navbar = ({ toggleSideNav }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [SearchTerm, setSearchTerm] = useState('');
    const [response, setresponse] = useState('');
    //const history = useHistory();


    async function search(query) {
        setSearchTerm(query);
        try {
            const products = await axios.get(`https://www.cazasouq.com/index.php?route=journal3/search&search=${query}`)
            var product = products.data.response
            setresponse(product)

        } catch (error) {
            console.error(error)
        }

    }
    return (
        <SimpleGrid as='header' columns={5} row={1} spacing={3} mt={6} mb={9}>
            <GridItem colSpan={{ base: 3, lg: 1 }}>
                <HStack as={Link} to='/' fontSize='4xl' maxW='220px' w='full'>
                    <Link
                        className="navbar-brand"
                        title="LMTV"
                        to="/"
                        style={{ fontFamily: '"Rampart One"', fontSize: 30, color: '#000' }}
                    >
                        PART<em style={{ fontStyle: "normal", color: "rgb(209, 0, 0)" }}>TECH</em>
                    </Link>

                    {/*<Heading as='h2' fontSize='2xl'>MovieLister</Heading>*/}

                </HStack>
            </GridItem>
            <GridItem colSpan={{ base: 5, lg: 2 }}>
                <InputAsButton onOpen={onOpen} />
            </GridItem>
            <GridItem colSpan={{ base: 5, lg: 6 }} colStart={{ base: 4, lg: 5 }} rowStart={1}>

                <HStack justify='flex-end' >
                    <HStack justify='flex-end'>
                        <Link id="cart" title="cart" to="/cart">
                            <IconButton display={{ base: 'none', sm: 'flex' }} icon={<BsCartCheckFill />} />
                        </Link>

                    </HStack>
                    <IconButton onClick={toggleSideNav} display={{ base: 'flex', md: 'none' }} icon={<IoMenu />}></IconButton>
                </HStack>
            </GridItem >
            <Modal isOpen={isOpen} onClose={() => {
                onClose();
                setSearchTerm('');
            }}>
                <ModalOverlay />
                <ModalContent rounded="xl" p={6} mx={6} w="100%">
                    <ModalHeader p={0}>
                        <InputGroup>
                            <InputLeftElement pointerEvents="none" children={<IoSearchSharp />} />
                            <Input
                                onChange={e => { search(e.target.value); }}
                                type="text"
                                placeholder="Search ..."
                                variant="filled"
                            />
                        </InputGroup>
                    </ModalHeader>
                    <ModalBody
                        p={0} mt={6}
                        display={SearchTerm === '' ? "none" : "block"}>

                        {(response && response) && <VStack w='full' spacing={6}>
                            {response.slice(0, response.length - 1).map((val, key) => {
                                return (
                                    <>

                                        <Box key={key} w='full' >
                                            <Divider mb={3} />
                                            <a href={"/price/" + val.href.split('/')[3].split('?')[0]} >
                                                <HStack cursor='pointer' spacing={6}>
                                                    <AspectRatio ratio={2 / 3} w='15%' objectFit="cover">

                                                        <Image alt={val.title || val.name} fit='cover' rounded='lg' src={val['thumb']} />

                                                    </AspectRatio>
                                                    <div className="search-result-item">
                                                        <div className="search-result-item__details">
                                                            <span className="search-result-item__details-title">{val.name}</span>
                                                            <span className="search-result-item__details-text">
                                                                <small style={{ display: "block" }}>{val.price}</small>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </HStack>
                                            </a>

                                        </Box>

                                    </>
                                );
                            })}
                        </VStack>}
                        {(response && !response) && <Center w='full'>
                            <Spinner />
                        </Center>}

                    </ModalBody>
                </ModalContent>
            </Modal>
        </SimpleGrid>
    );
};

export default Navbar;
