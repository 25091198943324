import { AspectRatio, Box, Button, Center, Heading, HStack, Image, Skeleton, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { Link } from 'react-router-dom';
const Productcard = ({ img, name, price, isLoading, aspect, id, url }) => {

    const [imageIsLoading, setImageIsLoading] = useState(true);


    useEffect(() => {
        if (isLoading === true) {
            setImageIsLoading(true);
        }
    }, [isLoading]);

    //var title = name.split(' ')


    return (

        <VStack cursor='pointer' align='start' _hover={{ transform: 'scale(1.05)' }} transition='.25s ease-in-out' maxW='320px' bg={'#FFFFFF'}>
            <Skeleton w='full' isLoaded={!isLoading && !imageIsLoading}>
                <AspectRatio ratio={aspect ? aspect : 2 / 2} w='full' objectFit="cover">
                    <Image alt={name} fit='cover' src={img} onLoad={() => setImageIsLoading(false)} />
                </AspectRatio>
            </Skeleton>
            <Skeleton maxW='full' isLoaded={!isLoading} w="100%" style={{ margin: "0px", padding: "1pc" }}>
                <Link className='url' to={encodeURI("/price/" + url)}>
                    <Heading maxW='full' as='h3' fontSize='11px' isTruncated >{name}</Heading>
                </Link>
                <span style={{ display: 'block' }}>
                    BHD {parseInt(price.replace(",", "").split('SAR')[1] / 10)}

                </span>
                <Center width="100%" >
                    <Button width={"100%"}>
                        Add to cart
                    </Button>
                </Center>


            </Skeleton>


        </VStack>

    );
};

export default Productcard;
