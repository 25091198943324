import React, { Component } from 'react'
import { Image, Heading, HStack, VStack, Text, Divider, Badge, Box, GridItem, Stack, Button, SimpleGrid, Link } from "@chakra-ui/react";

import CardP from '../../components/CardP'
class Categories extends Component {

  state = {
    Product: [],
    page: 1
  }

  render() {
    const { Product, page } = this.state
    const { categories } = this.props.match.params

    return (
      <>
        <VStack spacing={6} align='start' py={6} >
          <Stack w='full' spacing={{ base: 3, lg: 6 }} direction={{ base: 'row', lg: 'column' }} align={{ base: 'center', sm: 'start' }}>
            <Heading whiteSpace='nowrap' textTransform={{ base: 'none', lg: 'uppercase' }} as='h4' fontSize={{ base: '2xl', lg: 'md' }}>{categories}</Heading>
            <Box as='hr' w='full' />
          </Stack>


          <CardP categories={categories} />

          {Product && <Button as={Link} to='/special' w='full' colorScheme='green'>View More</Button>}

        </VStack>

      </>
    )

  }
}



export default Categories;

