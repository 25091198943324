import { Container, GridItem, SimpleGrid } from "@chakra-ui/react";
import { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CategoriesNav from "./components/Categories/CategoriesNav";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Categories from "./pages/Categories"
import Price from "./pages/price"
import './index.css'
//import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
//import "../node_modules/bootstrap/"
const App = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };



  return (
    <Container as='main' maxW={{
      xs: 'full',
      md: '720px',
      lg: '960px',
      xl: '1140px',
      xxl: '1320px'
    }} >
      <BrowserRouter >
        <Navbar toggleSideNav={toggleSideNav} />
        <SimpleGrid columns={5} row={1} spacing={6}>

          <GridItem colSpan={{ base: 5, md: 4 }} >
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/:categories' component={Categories} />
              <Route exact path='/price/:categories' component={Price} />
            </Switch>
          </GridItem>

          <GridItem>
            <CategoriesNav isOpen={isOpen} toggleSideNav={toggleSideNav} setIsOpen={setIsOpen} />
          </GridItem>
        </SimpleGrid>

        <Footer />
      </BrowserRouter>
    </Container>
  );

};



export default App;
