import { SimpleGrid, GridItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Productcard from '../Productcard';
import axios from 'axios';
import categorieso from '../Categories/categories'
import InfiniteScroll from 'react-infinite-scroll-component';
const CategoriesNav = ({ categories }) => {

    const [Product, setProduct] = useState([])
    const [page, setpage] = useState(1)
    //    const [loading, setloading] = useState(true)
    // const [back,setback] = useState({})





    var categ = categorieso.filter((res) => {
        return res.name == categories
    })


    const loadproducts = async () => {

        const backdata = await axios.get("https://raw.githubusercontent.com/moomle0000/backlmtv/main/data.json")

        var url = backdata.data.partech.back

        const { data } = await axios.get(`${url}/api/${categ[0].path}?page=${page}`)
        setProduct((prev) => [...prev, ...data])
    }

    useEffect(() => {
        loadproducts()



    }, [page]);




    return (
        <InfiniteScroll
            dataLength={Product.length}
            next={() => {
                //loadproducts()
                setpage(page + 1)
            }
            }
            hasMore={true}
        >
            <SimpleGrid w='full' columns={{ base: 1, xs: 2, sm: 3, md: 4 }} row={2} spacing={6} >
                {Product && Product.map((val, key) => {
                    return (
                        <GridItem key={key} w='full'>
                            <Productcard
                                img={val['img']}
                                name={val['name']}
                                isLoading={false}
                                id={val['id']}
                                price={val['price']}
                                url={val['name']}

                            />
                        </GridItem>
                    );
                })}
            </SimpleGrid>
        </InfiniteScroll>
    )
};

export default CategoriesNav;








