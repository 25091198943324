import { VStack, Heading, Box, useColorModeValue, IconButton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import categories from './categories';
import toProperCase from './toProperCase';
import { FaTimes } from 'react-icons/fa';
import NavOverlay from './NavOverlay';

const CategoriesNav = ({ isOpen, toggleSideNav, setIsOpen }) => {

    useEffect(() => {
        if (isOpen === true) {
            document.getElementsByTagName("html")[0].classList.add("no-scroll");
        } else {
            document.getElementsByTagName("html")[0].classList.remove("no-scroll");
        }
    }, [isOpen]);

    return (
        <>
            <NavOverlay visibility={isOpen ? 'visible' : 'hidden'} setIsOpen={setIsOpen} />
            <VStack
                p={{ base: 4, md: 0 }}
                py={6}
                w={{ base: '220px', md: 'full' }}
                align='start'
                transition='right 0.5s'
                boxShadow={{ base: 'dark-lg', md: 'none' }}
                spacing={6}
                pos={{ base: 'fixed', md: 'relative' }}
                zIndex='banner'
                overflowY='auto'
                h='100%'
                right={{ base: isOpen ? 0 : '-100%', md: 0 }}
                top={0}
            >
                <IconButton py={3} alignSelf='flex-end' onClick={() => toggleSideNav()} display={{ base: 'flex', md: 'none' }} icon={<FaTimes />}></IconButton>
                <Heading textTransform='uppercase' as='h4' fontSize='md' color={'#000'}>Categories</Heading>

                <Box as='hr' w='full' />
                <VStack spacing={1} align='start' w='full' fontSize='md' fontWeight='normal' color={'#000'}>
                    {categories.map((val, key) => {
                        return (
                            <a href={"/" + val.name} key={key} > {toProperCase(val.name)}</a>
                        );
                    })}
                </VStack>
            </VStack>
        </>
    );
};

export default CategoriesNav;
