import { Text, VStack/*, Wrap,Heading, HStack */ } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';
//import { ImVideoCamera } from 'react-icons/im';

const Footer = () => {
    return (
        <footer>
            <VStack>
                <Text align='center'>Copyright Ⓒ by Moomle - Designed by  <Link fontWeight='semibold' to='/' isExternal='true'>LM</Link></Text>
            </VStack>
        </footer>
    );
};

export default Footer;
