import { Heading, Divider, SimpleGrid, GridItem, VStack, Spacer, Button, HStack, useColorModeValue, Center, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Productcard from '../../../components/Productcard';
import axios from 'axios';
import Header from './Header'
const Home = ({ categories, name }) => {
    document.title = "LMTV - Watch TV Shows Online, Watch Movies Online"

    const [response, setresponse] = useState([])

    useEffect(async () => {

        const backdata = await axios.get("https://raw.githubusercontent.com/moomle0000/backlmtv/main/data.json")

        var url = backdata.data.partech.back

        axios.get(`${url}/api/${categories}`)
            .then(res => {
                setresponse(res.data)
            })



    }, [])
    return (
        <VStack bg={'#F8F8F8'}>
            <Header type={name} />
            <SimpleGrid w='full' columns={{ base: 1, xs: 2, sm: 3 }} row={2} spacing={6}>
                {response && response.slice(0, 3).map((val, key) => {
                    return (
                        <GridItem key={key}>
                            <Productcard
                                img={val['img']}
                                name={val['name']}
                                isLoading={false}
                                id={val['id']}
                                price={val['price']}
                                url={val['url']}

                            />
                        </GridItem>
                    );
                })}

            </SimpleGrid>
        </VStack>

    );
};

export default Home;
