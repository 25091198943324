import { Heading, Divider, SimpleGrid, GridItem, VStack, Spacer, Button, HStack, useColorModeValue, Center, Spinner } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Productcard from '../../components/Productcard';
import Categories from './CategoriesProduct';
import axios from 'axios';
import RecentlyAdded from './RecentlyAdded';
const Home = () => {
    document.title = "partech"

    return (
        <VStack >
            <SimpleGrid columns={4} row={1} spacing={6} pb={{ base: 0, md: 6 }} >
                {/*<GridItem colSpan={{ base: 4, lg: 4 }} bg={"#F8F8F8"} p={{ base: 3, md: 6 }} rounded='xl'>
                    <RecentlyAdded />
    </GridItem>*/}
                <GridItem colSpan={{ base: 4, lg: 4 }} bg={"#F8F8F8"} p={{ base: 3, md: 6 }} rounded='xl' >
                    <Categories categories={"keyboards"} name="keyboards" />
                    <Categories categories={"Gaming-mouse/logitech-mouse"} name="Mouse" />
                    <Categories categories={"monitors"} name="Monitors" />
                    <Categories categories={"playstation-4"} name="Playstation 4" />
                    <Categories categories={"PC-compn"} name="Computer Components" />
                    <Categories categories={"Motherboard"} name="Motherboard" />
                    <Categories categories={"microphone"} name="Microphone" />
                    <Categories categories={"capture-cards"} name="Capture Cards" />
                    <Categories categories={"computer-accessories/mousepad"} name="Mousepad" />
                    <Categories categories={"Discount-pc"} name="Computer Components" />



                </GridItem>

            </SimpleGrid>
        </VStack>
    );
};

export default Home;
